import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "typography"
    }}>{`Typography`}</h1>
    <p>{`Documentation and examples for typography.`}</p>
    <h2 {...{
      "id": "font-style"
    }}>{`Font style`}</h2>
    <p className="mb-3">
  The default font is{" "}
  <a className="pink" href="https://fonts.google.com/specimen/Open+Sans" target="_blank">
    Google Fonts Open Sans
  </a>
    </p>
    <p>{`Default size is `}<inlineCode parentName="p">{`14px`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "heading"
    }}>{`Heading`}</h2>
    <table className="p-2 border-radius box" style={{
      "maxWidth": "400px"
    }}>
  <thead>
    <tr>
      <th>Heading</th>
      <th>Exemple</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>h1</td>
      <td>
        <h1>Title 1</h1>
      </td>
    </tr>
    <tr>
      <td>h2</td>
      <td>
        <h2>Title 2</h2>
      </td>
    </tr>
    <tr>
      <td>h3</td>
      <td>
        <h3>Title 3</h3>
      </td>
    </tr>
    <tr>
      <td>h4</td>
      <td>
        <h4>Title 4</h4>
      </td>
    </tr>
    <tr>
      <td>h5</td>
      <td>
        <h5>Title 5</h5>
      </td>
    </tr>
    <tr>
      <td>h6</td>
      <td>
        <h6>Title 6</h6>
      </td>
    </tr>
  </tbody>
    </table>
    <p>{`Code, using Html Tags or using classes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<h1>Heading 1</h1>
<h2>Heading 2</h2>
<h3>Heading 3</h3>
<h4>Heading 4</h4>
<h5>Heading 5</h5>
<h6>Heading 6</h6>
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<p class="h1">Heading 1</p>
<p class="h2">Heading 2</p>
<p class="h3">Heading 3</p>
<p class="h4">Heading 4</p>
<p class="h5">Heading 5</p>
<p class="h6">Heading 6</p>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      